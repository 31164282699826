<template>
  <path id="Tracé_583" data-name="Tracé 583" d="M30.654,55.916a1.213,1.213,0,0,1,1.055-1.353l.4-.052a1.212,1.212,0,1,1,.329,2.4l-.434.056a1.227,1.227,0,0,1-.151.009A1.211,1.211,0,0,1,30.654,55.916Zm-7.646.714A28.592,28.592,0,0,1,2.617,16.646,28.559,28.559,0,0,1,55.058,39.239l7.2-3.5a1.211,1.211,0,0,1,1.623.56h0a1.214,1.214,0,0,1-.56,1.622L53.33,42.773a1.208,1.208,0,0,1-.532.122,1.233,1.233,0,0,1-.4-.07,1.209,1.209,0,0,1-.692-.631l-4.69-10.064a1.212,1.212,0,0,1,2.2-1.025l3.5,7.5A26.138,26.138,0,1,0,17.64,52.352a26.467,26.467,0,0,0,5.843,1.9,1.212,1.212,0,0,1-.235,2.4A1.139,1.139,0,0,1,23.009,56.63Zm15.618-2.561a1.214,1.214,0,0,1,.622-1.6l.359-.162A1.212,1.212,0,1,1,40.636,54.5l-.411.187a1.21,1.21,0,0,1-1.6-.622Zm7.089-4.094a1.211,1.211,0,0,1,.107-1.71l.3-.268a1.213,1.213,0,0,1,1.633,1.794l-.33.293a1.212,1.212,0,0,1-1.711-.109Zm-17.209-8.44L14.973,35.883a1.211,1.211,0,0,1-.746-1.119V20.434a.418.418,0,0,1,.01-.046,1.182,1.182,0,0,1,.049-.251,1.284,1.284,0,0,1,.033-.166l0,0c.006-.014.016-.025.023-.039a1.147,1.147,0,0,1,.13-.194,1.1,1.1,0,0,1,.112-.162c.008-.008.019-.011.029-.02a1.219,1.219,0,0,1,.175-.118.989.989,0,0,1,.225-.128c.013-.005.023-.02.04-.023L28.193,14.9a1.2,1.2,0,0,1,.767,0L42.1,19.284c.01,0,.017.011.026.014a.427.427,0,0,0,.044.015,1.163,1.163,0,0,1,.2.122,1.222,1.222,0,0,1,.167.107.22.22,0,0,1,.026.023,1.106,1.106,0,0,1,.122.169,1.19,1.19,0,0,1,.13.186c0,.011.014.016.019.027a.012.012,0,0,0,0,.007,1.5,1.5,0,0,1,.037.175,1.3,1.3,0,0,1,.054.249c0,.016.007.031.007.048s0,0,0,.007v14.33a1.215,1.215,0,0,1-.723,1.109L29.465,41.521a.041.041,0,0,1-.01,0,1.2,1.2,0,0,1-.482.1A1.213,1.213,0,0,1,28.507,41.534ZM16.653,33.956,27.76,38.594V26.819L16.653,22.243ZM30.186,26.8v11.75L40.5,33.976V22.288ZM18.914,20.554,28.958,24.69l9.38-4.1-9.763-3.253Z" transform="translate(0.583 0.574)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</template>

<script>
export default {
name: "IconMateriel"
}
</script>

<style scoped>

</style>