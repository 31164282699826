import { render, staticRenderFns } from "./IconMateriel.vue?vue&type=template&id=d088dba8&scoped=true&"
import script from "./IconMateriel.vue?vue&type=script&lang=js&"
export * from "./IconMateriel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d088dba8",
  null
  
)

export default component.exports